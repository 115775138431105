.header{
    margin-bottom: 30px !important;
    background-color: white !important;
    padding: 0 22px 0 0 !important;
    border-radius: 6px!important;
    box-shadow: 0 2px 2px 0 !important;
}

.header .header_link{
    font-weight: 500 !important;
    color: darkslategray !important;
}

.header .header_link:hover{
    font-weight: 500 !important;
    color: var(--main-color) !important;
}

.header .header_link_active{
    font-weight: 700 !important;
    color: var(--main-color) !important;
}

.header .header_right{
    display: flex !important;
    align-items: center !important;
}

.header .header_left{
    margin-right: auto !important;
}

.header .header_right > a{
    font-size: 20px !important;
    color: black !important;
    padding-left: 7px;
}

.header .header_home{
    background-color: var(--main-color) !important;
    padding: 22px !important;
    display: flex !important; 
    justify-content: center !important;
    align-items: center !important;
    border-top-left-radius: inherit !important;
    border-bottom-left-radius: inherit !important;
}

.header .header_navlink{
    padding: 0 !important;
    border-radius: 6px !important;

}


