.footer{
    background-color: black;
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
    border-radius: 8px;
    padding: 10px 25px;
}

.footer .footer_left{
    text-align: end;
    font-size: small;
    color: #9c9c9c;
}

.footer .footer_left > a{
    color: var(--main-color);
}


