.timeline{
    padding: 0% !important;
}

/* Header*/
.timeline .timeline_header{
    padding-top: 7px;
}

.timeline .timeline_firstItem{
    min-height: 80px !important;
}

.timeline .MuiTimelineItem-root{
    min-height: 0px;
}

.timeline .MuiTimelineItem-missingOppositeContent:before{
    display: none;
}

.timeline .timeline_dot_header{
    color: black;
    background-color: var(--main-color);
    font-size: small !important;
    padding: 10px !important;
}

.timeline .timeline_dot_header > .MuiSvgIcon-root{
    font-size: 20px;
    color: darkslategray;
}

/* ITEMS */

.timeline .timeline_dot{
    color: white;
    border-color: var(--main-color);
    padding: 2px !important;
}

.timeline .MuiTimelineConnector-root{
    background-color: #eee;
}
.timeline .seperator_padding{
    padding-left: 18px;
}
.timeline .timeline_content{
    padding-top: 0% !important;
    margin-bottom: 20px;
}
