/* Timeline Section */
.resume_timeline .timeline_title{
    font-size: 16px !important;
    font-weight: 600 !important;
    color: darkslategray !important;

}

.resume_timeline .timeline_title > span{
    font-size: 14px !important;
    font-weight: 600 !important;
}

.resume_timeline .cert_title{
    font-size: 16px !important;
    font-weight: 600 !important;
    color: darkslategray !important; 
}

.resume_timeline .cert_title > a{
    font-size: 16px !important;
    font-weight: 600 !important;
    color: darkslategray !important;
    text-decoration: none !important; 
}

.resume_timeline .cert_title > a > span{
    font-size: 14px !important;
    font-weight: 600 !important;
    color: darkslategray !important;
    text-decoration: none !important; 
}

.resume_timeline .cert_title > span{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: darkslategray !important;
    text-decoration: none !important; 
}

.resume_timeline .cert_title > a:hover{
    font-weight: 500px !important;
    color: var(--main-color) !important;
}

.resume_timeline .timeline_date{
    font-size: small;
}

.resume_timeline .timeline_description{
    color: grey;
    font-size: small;
    margin-top: 6px;
    white-space: pre-wrap;
}

.resume_timeline .cert_image{
    max-width: 100px;
    max-height: 100px;
}

.resume_timeline .cert_image_container{
    margin-left: 20px;
}

